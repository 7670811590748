<template>
  <nav :aria-label="$t.utilityNavigationLabel">
    <ul class="f-col items-start gap-4">
      <li v-if="$feature.enableLoyaltyUtilityNav && showLoyalty">
        <vf-link
          v-if="auth.loggedIn"
          class="i-block"
          to="/account/loyalty"
          variant="quiet"
          @click="handleClick('loyalty')"
        >
          {{ $t.earnRewards }}
          <template v-if="isLoyaltyMember">
            <template v-if="!vouchersPending && !vouchers?.applicableLoyaltyVouchers?.length && loyaltyPointsBalance">
              | {{ loyaltyPointsBalance }} {{ $pluralize($t.points, loyaltyPointsBalance) }}
            </template>
            <template v-else-if="vouchers?.applicableLoyaltyVouchers?.[0]?.available">
              | {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: currentReward }) }}
            </template>
          </template>
        </vf-link>
        <vf-link v-else variant="quiet" @click="handleSignIn">
          {{ $t.loyaltyGuest }}
        </vf-link>
      </li>
      <template v-for="({ type, to, title }, key) in links" :key>
        <li>
          <vf-link
            v-if="type === 'chat' && $feature.enableChat"
            class="items-center gap-1 i-flex"
            data-chat="utility-nav"
            variant="quiet"
            @click="handleChat(title)"
          >
            {{ title }}
            <vf-icon name="chat-alt" size="sm" />
          </vf-link>
          <vf-link v-else-if="type !== 'chat'" class="i-block" :to variant="quiet" @click="handleClick(title)">
            {{ title }}
          </vf-link>
        </li>
      </template>
      <li v-if="showClearSessionButton && buyInStore.storeInfo?.storeId">
        <vf-link variant="quiet" @click="buyInStore.clearSession">
          {{ $t.clearSession }}
        </vf-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationLink } from '#types/navigation'

defineProps<{
  links: NavigationLink[]
  showLoyalty?: boolean
}>()

const emit = defineEmits<{
  /**
   * Emits when a navigation item is clicked
   */
  click: []
  /**
   * Emits when loyalty navigation item is clicked
   */
  signIn: []
}>()

const { showClearSessionButton } = useAppConfig().checkout.buyInStore
const auth = useAuthStore()
const buyInStore = useBuyInStoreStore()
const { currentReward, vouchersPending, vouchers } = storeToRefs(useLoyaltyStore())
const { $chat, $gtm } = useNuxtApp()
const { isLoyaltyMember, loyaltyPointsBalance } = storeToRefs(useUserStore())

const handleClick = (title: string) => {
  emit('click')
  $gtm.push('topNav.onClickUtilityLink', fullCapitalize(title))
}

const handleChat = (title: string) => {
  handleClick(title)
  $chat.open()
}

const handleSignIn = (title: string) => {
  emit('signIn')
  handleClick(title)
}
</script>
